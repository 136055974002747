var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-2 font-weight-bold mb-5"},[_vm._v("Projectnummers")]),_c('p',[_vm._v(" Hier ziet u alle projectnummers opgesomd en welke actief zijn. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.projects,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Projectnummers")])],1)]},proxy:true},{key:"item.project_nr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_nr)+" ")]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_name)+" ")]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.timestamp).toLocaleString())+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Actief' : 'Inactief')+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }