





































import Vue from "vue";
import axios from "axios";
import { Project } from "@/types/project";

export default Vue.extend({
  name: "RulesComponent",
  data: () => ({
    projects: [] as Project[],
    headers: [
      {
        text: "Projectnummer",
        value: "project_nr",
        width: '10px',
      },
      {
        text: "Projectnaam",
        value: "project_name",
        width: '100px',
      },
      {
        text: "Bronhouder",
        value: "source_holder",
        width: '100px',
      },
      {
        text: "Actief",
        value: "active",
        width: '10px',
      },
      {
        text: "Laatst toegevoegd",
        value: "timestamp",
        width: '50px',
      },
    ],
  }),
  methods: {
    getProjects() {
      axios
        .get(`${this.$store.state.APIurl}/project`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.projects = response.data;
        })
        .catch(() => {
          this.$data.projects = [];
        });
    },
  },
  mounted() {
    this.getProjects();
  },
});
